@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
body {
  font-family: 'Open Sans', sans-serif;
  background-color: rgb(31, 41, 55);
  color: white;
  font-weight: 100;
}

.fp-watermark {
  display: none !important;
}

#fp-nav ul li a span,
.fp-slidesNav ul li a span {
  background: white !important;
}
.heading {
  font-family: -apple-system-ui-serif, ui-serif, 'Georgia', serif;
}
